.management {
  padding: 0 3rem;
  overflow-x: hidden;
  .h1 {
    margin-bottom: 2rem;
  }
  .management-info {
    height: fit-content;
    margin-bottom: 4rem;
    .flex-box {
      display: flex;
      gap: 1rem;
      height: fit-content;

      p {
        font-size: var(--para-size-medium);
        text-align: justify;
      }

      &__container {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        gap: 2rem;
        flex: 0 0 70%;
        overflow: hidden;
      }
      .management__image {
        flex: 0 0 29%;
        background-image: url("https://source.unsplash.com/user/erondu/1600x900");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 4px;
      }
    }
  }
}
.text-align {
  text-align: left;
  padding: 0 3rem;
}
// .content {
//   display: flex;
//   background-color: #f0eeee;
//   height: 100vh;
// }

// .content-view {
//   margin: 1rem 3rem;
//   flex: 1;
//   margin-left: 25rem;
//   overflow: hidden !important;
// }

.time-line {
  display: flex;

  p {
    font-size: var(--para-size-medium);
    margin-right: 5rem;
    display: flex;
  }
  div {
    display: flex;
    align-items: center;
  }
}

.clock-size {
  height: 30px !important;
  width: 30px !important;
  margin-right: 10px;
}

.cls1,
.cls2 {
  fill: none;
  stroke: #9e9e9e;
  stroke-miterlimit: 10;
  stroke-width: 4.55px;
}
.cls2 {
  stroke-linecap: round;
}

.cls1-s,
.cls2-s {
  fill: none;
  stroke: #9e9e9e;
  stroke-miterlimit: 10;
  stroke-width: 12.55px;
}
.cls2-s {
  stroke-linecap: round;
}
.clock-size--s {
  width: 2rem;
  height: 2rem;
}

.flex {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem 3rem;
}

.size {
  font-size: var(--para-size-medium);
}
.size-m-b {
  font-size: 18px !important;
  font-weight: bold;
  letter-spacing: 0.0211rem;
  width: calc(50vw - 50px) !important;
  @media screen and (min-width: 900px) and (max-width: 1300px) {
    font-size: var(--para-size-medium);
  }
}
.display-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 4.5%);
  padding: 1rem 1.5rem;
}

.cls-1--v {
  fill: none;
  stroke: #e35f14;
  stroke-miterlimit: 10;
  stroke-width: 1.5px;
}
.cls-2--v {
  fill: #e35f14;
}
.cls-1--v-g {
  fill: none;
  stroke: #777;
  stroke-miterlimit: 10;
  stroke-width: 1.5px;
}
.cls-2--v-g {
  fill: #777;
}

.cls-1--R {
  fill: none;
  stroke: #e35f14;
  stroke-miterlimit: 10;
  stroke-width: 2.97px;
}
.cls-2--R {
  fill: #e35f14;
}
.cls-1--R-g {
  fill: none;
  stroke: #777;
  stroke-miterlimit: 10;
  stroke-width: 2.97px;
}
.cls-2--R-g {
  fill: #777;
}

.cls-1--H {
  fill: none;
  stroke: #e35f14;
  stroke-miterlimit: 10;
  stroke-width: 1.5px;
}
.cls-2--H,
.cls-3--H {
  isolation: isolate;
}
.cls-3--H {
  font-size: 32.18px;
  fill: #e35f14;
  font-family: MyriadPro-Bold, Myriad Pro;
  font-weight: 700;
}
.cls-1--H-g {
  fill: none;
  stroke: #777;
  stroke-miterlimit: 10;
  stroke-width: 1.5px;
}
.cls-2--H-g,
.cls-3--H-g {
  isolation: isolate;
}
.cls-3--H-g {
  font-size: 32.18px;
  fill: #777;
  font-family: MyriadPro-Bold, Myriad Pro;
  font-weight: 700;
}
.video-size {
  height: 3rem;
  width: 3rem;
}

.Done_size {
  height: 2rem;
  width: 2rem;
  margin-left: 30px;
}

.cls1_D {
  fill: #1cbf04;
}

.cls2_D {
  fill: #fcfcfc;
}
.pdfGray {
  width: 3rem;
  height: 3rem;
  fill: rgb(151, 147, 147);
}
.pdfAct {
  width: 3rem;
  height: 3rem;
  fill: #e35f14;
}
.quizdark {
  width: 3rem;
  height: 3rem;
  fill: rgb(151, 147, 147);
  margin-bottom: 3px;
}
.quizAct {
  width: 3rem;
  height: 3rem;
  fill: #e35f14;
  margin-bottom: 3px;
}
.normalscreen {
  display: none !important;
}
.fullscreen {
  display: block;
  z-index: 1000;
}
.fullscreen:fullscreen {
  display: block;
  z-index: 1000;
}

.spinLoad {
  position: fixed;
  background-color: #000000a1;
  width: 100vw;
  height: 100vh;
  display: flex;
  top: 0;
  left: 0;
  z-index: 2000;
}
