.management {
    overflow-y: auto;
    .h1 {
      margin-bottom: 2rem;
    }
}

.lableText{
  font-size: medium !important;
  margin-left: 10%;
 
}
.textBox{
  font-size: medium;
 width:80%;
 border-color: grey;
 height:30px;
 padding-top: 8px;
 border-width: thin;
 display: flex;
 margin-left: 10%;
}

.browse{
  margin-left: 10%;
}
.issueText{
  margin-left: 10%;
  color: red;
}
.reviewText{
  font-size: medium !important;
  margin-left: 10%;
}
.reviewTextSpan{
  font-size: medium !important;
  margin-left: 0%;
  
}
.saveButton{
  color:white !important;
 border-style:groove !important;
 border-color:#E35F14 !important;
 height:25px !important;
 width:70px;
 line-height:0px !important;
 margin-right :22px !important;
 background-color: #E35F14 !important;
 font-size: 14px !important;
 border-radius: 3px !important;
 
}
.nextButton{
  color:#E35F14 !important;
 border-style:groove !important;
 border-color:#E35F14 !important;
 height:25px !important;
 width:70px;
 line-height:0px !important;
 margin-right :22px !important;
 background-color: white !important;
 font-size: 14px !important;
 border-radius: 3px !important;
}

.overview {
  margin: 5rem 3rem 0rem 3rem;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 500px) {
    margin: 0;
  }

  h1 {
    text-align: left;
  }
  &__h1 {
    text-align: left;
    color: var(--header-color);
  }

  &__card {
    margin: 1rem 0 2rem 0;
    display: flex;
    width: 100%;
    height: 180px;
    border-radius: 10% !important;
    @media screen and (max-width: 600px) {
      flex-direction: column;
      margin-bottom: 20px;
      display: grid;
    }
  }

  &__cardContent {
    flex: 0 0 75%;
    height: 100%;
    background-color: #fff;
    color: var(--para-color);
    border-radius: 0.5rem 0 0 0.5rem;
    font-size: var(--para-size-large);

    @media screen and (max-width: 600px) {
      font-size: var(--para-size-medium);
      border-radius: 0 0 0.5rem 0.5rem;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.6);
    }
  }
  &__cardImage {
    flex: 0 0 25%;
    height: 180px;
    background-color: #fff;
    border-radius: 0 0.5rem 0.5rem 0;
    @media screen and (max-width: 600px) {
      order: -1;
      border-radius: 0.5rem 0.5rem 0 0;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.6);
    }

    img {
      height: 100%;
      width: 100%;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      border-top-right-radius: 4%;
      border-bottom-right-radius: 4%;

      @media screen and (max-width: 600px) {
        border-radius: 0.5rem 0.5rem 0 0;
      }
    }
  }
  .content {
    &__details {
      margin: 2rem 3rem;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      flex: 1;

      .items {
        display: flex;
        justify-content: space-between;
        font-size: 16px;
        @media screen and (max-width: 500px) {
          display: block;
        }
        

        .align_credits {
          padding: 0 8.7rem 0 0;
          @media screen and (max-width: 600px) {
            padding: 0 8.5rem 0 0;
          }
        }
      }
    }
  }

  .btn_color {
    background-color: var(--button-background);
    color: var(--button-text);
  }
}