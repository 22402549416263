.qtable{
    border: 1px solid black;
	color : black;

}
.qtable tr td{
	border: 1px solid black !important;
	padding: 5px;
	width: 50% !important;
	color : black;
}
.qtable tr th{
	border: 1px solid black;
	padding: 5px;
	color : black;

}