.header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 2rem;

  &__icon {
    height: 2.75rem;
    width: 2.75rem;
  }
}
.dropdown.icon {
  display: none !important;
  color: #c4c4c4 !important;
}
.ui.dropdown .menu .selected.item,
.ui.dropdown.selected {
  margin-left: 20px !important;
  width: 10px !important ;
  margin-right: 70px !important;
  background: white;
}
.ui.dropdown .menu .selected.item,
.ui.dropdown.selected:hover {
  background: white;
}
.visible.transition {
  margin-left: -60px !important;
}
