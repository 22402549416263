.liststyle{
    max-width: 60%;
    min-width: 700px;
    padding: 20px 0 61px 0;
    overflow:hidden;
    font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
    font-size: var(--para-size-large);
}
.programTitle{
    color: #000;
    text-align: justify;
    font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
    font-size: var(--para-size-large);
    margin: 0px;
}
.institutionName{
    color: #000;
    text-align: justify;
    font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
    font-size: var(--para-size-large);
}
.hedingStyle{
    color:gray;
    font-weight: bold;
    font-size: 24px !important;
    
}

