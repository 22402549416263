.player-wrapper {
    position: relative;
    // padding-top: 56.25% /* Player ratio: 100 / (1280 / 720) */
  }
  
  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }
  
  .body {
    display: flex;
    justify-content: center;
    align-items: center;
    //height: 45vh;
    color: black;
    position: relative;
    flex-direction: column;
    width: inherit;

  }
  
  .img_container {
    width: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  
    padding: 1rem;
    display: flex;
    justify-content: center;
  }
  .heading1 {
    align-self: center;
  }
  .app1 {
    position: absolute;
    background-color: #fff;
    width: 80%;
    min-height: 70vh;
    height: min-content;
    border-radius: 15px;
    padding: 20px;
    box-shadow: 10px 10px 42px 0px rgba(0, 0, 0, 0.75);
    z-index: 10000000000;
    // display: grid;
    // place-items: center;
    // flex-direction: column;
    align-items: center;
    // justify-content: center;
  
    @media screen and (min-width: 1080px) and (max-width: 1400px) {
      width: 700px;
    }
    @media screen and (min-width: 700px) and (max-width: 1079px) {
      width: 650px;
    }
  }
  
  .score-section {
    display: flex;
    min-height: 50vh;
    font-size: 24px;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
    text-align: center;
    justify-content: center;
  }
  
  .scrollQuiz {
    overflow-y: auto;
    max-height: 65vh;
    overflow-x: hidden;
  }
  
  /* QUESTION/TIMER/LEFT SECTION */
  .question-section {
    width: 100%;
    position: relative;
  }
  
  .question-count {
    margin-bottom: 20px;
  }
  
  .question-count span {
    font-size: 28px;
  }
  
  .question-text {
    margin-bottom: 12px !important;
    font-size: 14px;
  }
  
  .timer-text {
    background: rgb(230, 153, 12);
    padding: 15px;
    margin-top: 20px;
    margin-right: 20px;
    border: 5px solid rgb(255, 189, 67);
    border-radius: 15px;
    text-align: center;
  }
  
  /* ANSWERS/RIGHT SECTION */
  .answer-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .btnQuiz {
    font-size: 16px;
    color: #ffffff;
    background-color: #252d4a;
    border-radius: 15px;
    display: flex;
    padding: 5px;
    justify-content: flex-start;
    align-items: center;
    border: 5px solid #234668;
    cursor: pointer;
  }
  
  .buttonQuiz {
    width: 98%;
    font-size: 16px;
    color: black;
    background-color: white;
    border-radius: 15px;
    display: flex;
    padding: 5px;
    justify-content: flex-start;
    //align-items: center;
    border: 2px solid #234668;
    cursor: pointer;
    margin: 0.5rem;
    text-align: center;
  }
  .buttonQuizAR {
    width: 70%;
    font-size: 16px;
    color: black;
    background-color: white;
    border-radius: 15px;
    display: flex;
    padding: 5px;
    justify-content: flex-start;
    align-items: center;
    border: 1px solid #234668;
    cursor: pointer;
    margin: 0.5rem;
    text-align: center;
  }
  .btnQuizLD {
    width: 100px;
    height: 40px;
    font-size: 13px;
    color: #ffffff;
    background-color: #252d4a;
    padding: 0.6rem;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 5px solid #234668;
    cursor: pointer;
    margin: 1rem;
    text-align: center;
  }
  
  .buttonQuizCt {
    width: 100%;
    font-size: 16px;
    color: #ffffff;
    background-color: #252d4a;
    padding: 0.6rem;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 5px solid #234668;
    cursor: pointer;
    margin: 1rem;
    text-align: center;
  }
  
  .btn-center {
    width: 100%;
    font-size: 16px;
    color: #ffffff;
    background-color: #252d4a;
    border-radius: 15px;
    border: 5px solid #234668;
    cursor: pointer;
    margin: 0.5rem;
    text-align: center;
  }
  
  .correct {
    background-color: #2f922f;
  }
  
  .incorrect {
    background-color: #ff3333;
  }
  
  .buttonQuiz:hover {
    background-color: #c9cad1;
  }
  
  .buttonQuiz:focus {
    outline: none;
  }
  
  .buttonQuiz svg {
    margin-right: 5px;
  }
  
  .correct {
    background-color: green;
    width: 100%;
  }
  
  .wrong {
    background-color: red;
    width: 100%;
  }
  
  .file {
    opacity: 0;
    width: 0.1px;
    height: 0.1px;
    position: absolute;
  }
  .file-input {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
  }
  .file-input label {
    display: block;
    position: relative;
    width: 100px;
    height: 30px;
    border-radius: 25px;
    background: linear-gradient(40deg, #252d4a, #234668);
    box-shadow: 0 4px 7px rgba(0, 0, 0, 0.4);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-weight: bold;
    cursor: pointer;
    transition: transform 0.2s ease-out;
  }
  
  .file-name {
    position: absolute;
    bottom: -35px;
    left: 10px;
    font-size: 0.85rem;
    color: #555;
  }
  
  /* Adding an outline to the label on focus */
  
  // .MuiInputBase-input {
  //   color: #fff !important;
  //   font-size: 12.5px !important;
  //   letter-spacing: 0.3px;
  // }
  
  .btn-size {
    align-self: flex-start !important;
    display: inline-block !important;
  }
  
  .btn-siz {
    align-self: flex-end;
    display: inline-block;
  }
  .TickRight {
    color: #0e5c0e;
    width: "30px" !important;
    height: "30px" !important;
  }
  .right_size {
    height: 2rem;
    width: 2rem;
    margin-top: 8px;
    margin-left: 10px;
  }
  .Wrong_size {
    height: 2rem;
    width: 2rem;
    margin-top: 8px;
    margin-left: 10px;
  }
  
  .cls1_D {
    fill: #1cbf04;
  }
  .normalscreen {
    display: none !important;
  }
  .fullscreen {
    display: block;
    z-index: 1000;
  }
  .fullscreen:fullscreen {
    display: block;
    z-index: 1000;
  }
  .buttonQuizFb {
    width: 98%;
    font-size: 18px;
    color: #000;
    // background-color: #234668;
    border-radius: 15px;
    display: flex;
    padding: 5px;
    justify-content: flex-start;
    //align-items: center;
    border: 2px solid #999999;
    cursor: pointer;
    margin: 0.5rem;
    text-align: center;
  }
  .buttonQuizFb2 {
    width: 98%;
    font-size: 18px;
    color: #fff;
    background-color: #0e5c0e;
    border-radius: 15px;
    display: flex;
    padding: 5px;
    justify-content: flex-start;
    //align-items: center;
    border: 2px solid #fff;
    cursor: pointer;
    margin: 0.5rem;
    text-align: center;
  }
  .buttonQuizFb3 {
    width: 98%;
    font-size: 18px;
    color: #fff;
    background-color: #ff3333;
    border-radius: 15px;
    display: flex;
    padding: 5px;
    justify-content: flex-start;
    //align-items: center;
    border: 2px solid #fff;
    cursor: pointer;
    margin: 0.5rem;
    text-align: center;
  }
  .buttonQuizSq {
    width: 95%;
    font-size: 16px;
    color: #0f80a4;
    background-color: #fff;
    border-radius: 4px;
    display: flex;
    padding: 3px 5px;
    justify-content: flex-start;
    //align-items: center;
    border: 2px solid #0f80a4;
    cursor: pointer;
    margin: 0.5rem;
    text-align: center;
  }
  .buttonFb {
    border: 0;
    background-color: #0f80a4;
    padding: 0.5rem 3rem;
    margin: 0 3rem;
    color: #fff;
    border-radius: 4px;
    font-size: 1.5rem;
    &:disabled {
      background-color: #777777;
      cursor: not-allowed;
    }
  }
  .buttonFb-a {
    border: 0;
    background-color: #fff;
    padding: 0.5rem 3rem;
    margin: 0 3rem;
    color: #0f80a4;
    border: 1px solid #0f80a4;
    border-radius: 4px;
    font-size: 1.5rem;
    &:disabled {
      background-color: #777777;
      cursor: not-allowed;
    }
  }
  .ddd {
    border: 3px solid #0f80a4 !important;
    border-radius: 15px !important;
  }
  